@import "../../shared.module.scss";

.CompanyInfo {
    background-color: $primaryColor;
    padding: 25px 0;
    color: #f8f8f8;
    font-size: 13px;
    line-height: 20px;

    @include gt-xs {
	font-size: 15px;
	max-width: 600px;
    }

    p {
	font-weight: 300;
	text-align: center;
	margin-block-start: 5px;
	margin-block-end: 5px;


	@include gt-xs {
	    text-align: left;
	    margin-block-start: 0;
	    margin-block-end: 0;
	}

	a {
	    color: inherit;
	    text-decoration: none;

	    &:hover {
		text-decoration: underline;
	    }
	}
    }

}
