@import "../../shared.module.scss";

.Logo {

    img {
	height: 25px;
	margin-left: 20px;

	@include gt-xs {
	    height: auto;
	    padding: 20px 0 0 0;
	}
    }

    @include gt-xs {
	display: block;
    }
}

.TopBar {
    position: fixed;
    top: 0;
    z-index: 10000;
    height: $topBarHeight;
}

.Bordered {
    border-bottom: 1px solid #ccc;
    background-color: $primaryColor;
}

.BackgroundBlue {
    border-bottom: 1px solid #ccc;
    background-color: $primaryColor;
}

.Menu {

    @include gt-xs {
	padding: 25px 0;
    }

    a {
	text-decoration: none;
	text-align: center;
	color: $primaryColor;
	padding-bottom: 20px;
	font-size: 14px;

	@include gt-xs {
	    font-size: 14px;
	    padding-bottom: 0px;
	    color: $guyabano;
	}
    }

    a:hover {
	border-bottom: 2px solid $guyabano;
    }
}

.Underlined {

    text-decoration: underline !important;
    font-weight: bold;

    @include gt-xs {
	text-decoration: none !important;
	border-bottom: 2px solid $guyabano;
    }
}

.Bold {
    font-weight: bold;
}
