.ScheduleView {

    margin-bottom: 50px;

    h2 {
	color: #4a4a4a;
	font-size: 28px;
    }

    h3 {
	color: #4a4a4a;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
    }

    small {
	color: #666;
    }

    .FormField {
	margin: 15px 0;
    }
}
