@import "../../shared.module.scss";


.Process {
    // background-color: $backgroundColor2;
    color: #fff;

    .Container {
	padding: 40px 0;
	background-color: $primaryColor;

	@include gt-xs {
	    margin: 60px 0;
	}
    }

    .Step {
	padding-bottom: 30px;
    }

    .Title {
	font-size: 22px;
	font-weight: 700;
	text-align: center;
	padding-bottom: 20px;

	@include gt-xs {
	    padding-bottom: 0;
	}
    }

    .Points {
	font-size: 13px;
	color: #fff;
	font-weight: 300;
	text-align: center;
	max-width: 80%;

	@include gt-xs {
	    text-align: left;
	    max-width: 100%;
	    font-weight: 400;
	    font-size: 14px;
	}

	p {
	    padding-bottom: 10px;

	    @include gt-xs {
		padding-bottom: 0px;
	    }

	}

	span {
            display: none;

            @include gt-xs {
		display: inline;
            }
	}
    }
}
