@import "../../shared.module.scss";

.Services {

    .Service {
	margin: 25px 0;
	color: #f0f0f0;
	text-align: center;
	text-shadow: 1px 1px 5px rgba(0,0,0,0.5);

	@include gt-xs {
	    margin: 100px 0;
	    padding: 0 25px;
	}

	.Icon {
	    margin-block-end: 0;
	    margin-bottom: 25px;

	    svg {
		font-size: 72px;
	    }

	    @include gt-xs {
		margin-bottom: 25px;
	    }
	}

	.Title {
	    font-size: 24px;
	    font-weight: 600;
	    padding: 0 20px;
	    margin-block-start: 0;

	    @include gt-xs {
		padding: 0;
	    }
	}

	.Description {
	    line-height: 28px;
	    font-weight: 300;
	    padding: 0 20px;

	    @include gt-xs {
		font-size: 18px;
		padding: 0;
	    }
	}

    }

}
