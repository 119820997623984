@import "../../shared.module.scss";

.Social {
    padding-bottom: 25px;
    background-color: $primaryColor;

    a.Icon {
	font-size: 30px;
	padding: 0px 15px;
	color: #fff;
	text-decoration: none;

	&:hover {
	    color: #e0e0e0;
	}
    }
}
