@import "../../shared.module.scss";

.Rationale {
    background-color: #fff;

    .WhyCustom {
	padding: 100px 0;
	// background-color: $temp;
	background-size: cover;

	p {
	    margin-block-start: 0;
	    margin-block-end: 0;
	    font-weight: 400;
	    padding-bottom: 5px;
	    font-size: 15px;
	    // color: #f8f8f8;

	    &:first-of-type {
		// color: $primaryColor;
		// color: #fff;
		font-size: 22px;
		line-height: 26px;
		letter-spacing: 0.01em;
		font-weight: 700;
		padding-bottom: 25px;
	    }
	}
    }

    .Dark {
	p {
	    color: #fff;
	}
    }

    .Light {
	p {
	    color: #666;

	    &:first-of-type {
		color: $primaryColor;
	    }
	}
    }

    .WhyUs {
	padding: 75px 0;

	.SectionTitle {
	    text-align: center;
	    font-size: 22px;
	    color: $primaryColor;
	    font-weight: 700;

	    @include gt-xs {
		font-size: 24px;
		margin-bottom: 75px;
	    }
	}

	.Point {
	    text-align: center;
	    max-width: 90%;


	    @include gt-xs {
		max-width: 100%;
	    }

	    p {
		font-weight: 300;
		color: #666;
		margin-block-start: 0;
		margin: 10px 0 25px 0;
		font-size: 15px;

		@include gt-xs {
		    font-weight: 400;
		    font-size: 16px;
		}
	    }

	    p:first-of-type {
		color: $primaryColor;
		font-weight: 700;
		margin-block-end: 0;
	    }
	}

    }
}
