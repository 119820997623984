@import "../../shared.module.scss";

.Location {
    text-align: center;

    img {
    	width: 100%;

	@include gt-xs {
	    margin-top: 50px;
	    max-width: 600px;
	}
    }
}
