@import "../../shared.module.scss";

.ContactFormWrapper {

    .ContactForm {
	width: 80%;

	@include gt-xs {
	    width: 100%;
	    padding-left: 10%;
	}

	h2 {
	    text-align: center;
	    font-weight: 300;
	    color: $guyabano;
	    font-size: 20px;
	    margin-block-end: 0;

	    @include gt-xs {
		color: $primaryColor;
		visibility: hidden;
	    }
	}

	button {
	    margin: 25px 0;
	}
    }
}
