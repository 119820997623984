@import "../../shared.module.scss";

.Projects {
    background-color: #f8f8f8;
    padding: 50px 0;

    .Project {
	font-weight: 400;
	color: #012b3c;
	max-width: 80%;

	.Title {
	    margin-block-end: 0;
	    font-size: 18px;
	}

	.Client {
	    margin-block-start: 0;
	    color: #7f7f7f;
	    font-weight: 700;
	    font-size: 20px;

	    @include gt-xs {
		font-size: 28px;
	    }

	}

	.Description {
	    line-height: 24px;
	    color: #4a4a4a;
	    font-weight: 300;
	}

	.Logo {
	    height: 100%;
	    width: 100%;

	    img {
		display: none;

		@include gt-xs {
		    display: inline;
		}
	    }
	}
    }

    button {
	margin-top: 60px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 40px;
	color: #012b3c;
	outline: none;
    }

    button:disabled {
	color: #ccc;
	cursor: auto;
    }

    .Arrow {
	display: none;

	@include gt-xs {
	    display: inline;
	}
    }

    .NextWrapper {
	text-align: center;
	background-color: #f8f8f8;
	padding-bottom: 25px;

	.Next {
	    display: inline;
	    font-size: 14px;
	    text-align: center;
	    margin-top: 0;

	    @include gt-xs {
		display: none;
	    }
	}
    }
}
