@import "../../shared.module.scss";

.LanguageSelector {

    .Bold {
	font-weight: bold;
    }

    a {
	text-transform: uppercase;
    }

    a:first-of-type::after {
	@include gt-xs {
	    content: ' | ';
	}
    }

    .MobileOption {
	text-align: center;
	background-color: $primaryColor;
	padding: 10px 0;

	a {
	    color: #fff;
	}
    }

}
