@charset "UTF-8";

@font-face {
    font-family: "solo-site-app";
    src:url("assets/fonts/solo-site-app.eot");
    src:url("assets/fonts/solo-site-app.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/solo-site-app.woff") format("woff"),
    url("assets/fonts/solo-site-app.ttf") format("truetype"),
    url("assets/fonts/solo-site-app.svg#solo-site-app") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "solo-site-app" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "solo-site-app" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-settings:before {
    content: "\61";
}
.icon-bubble-comment:before {
    content: "\63";
}
.icon-computer:before {
    content: "\64";
}
.icon-right-arrow:before {
    content: "\65";
}
.icon-left-arrow:before {
    content: "\66";
}
.icon-facebook:before {
    content: "\67";
}
.icon-github:before {
    content: "\68";
}
.icon-linkedin:before {
    content: "\69";
}
.icon-angle-left:before {
    content: "\6a";
}
.icon-angle-right:before {
    content: "\6b";
}
.icon-cancel:before {
    content: "\6c";
}
.icon-bars:before {
    content: "\6d";
}
.icon-hamburger:before {
    content: "\6e";
}
.icon-cogwheel:before {
    content: "\6f";
}
.icon-consulting:before {
    content: "\70";
}
.icon-monitor:before {
    content: "\71";
}
.icon-pencil:before {
    content: "\72";
}
