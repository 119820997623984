@import "../../shared.module.scss";

.Blog {
    background-color: $backgroundColor2;
    color: $textColor1;
}

.Cover {
    width: 100%;
    background-size: cover;
    background-position: center center;
    height: 350px;

    @include gt-xs {
	background-size: cover;
	height: 550px;
    }
}

.Title {
    padding-top: 50px;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    width: 100%;
    text-align: left;
}

.Author {
    font-size: 14px;
    margin-block-start: 0;
    margin-block-end: 0;

    a {
    	text-decoration: none;
	color: darken($temp, 35);

	&:hover {
	    text-decoration: underline;
	}
    }

}

.ReadTime {
    font-style: italic;
    font-size: 12px;
    color: #333;
    text-align: right;
}

.PublishedDate {
    font-size: 13px;
    margin-block-start: 0;
    margin-block-end: 0;
    color: #333;
}

.Avatar {
    max-height: 60px;
    border-radius: 30px;
}

.Text {
    margin-top: 45px;
    font-size: 16px;
    line-height: 28px;

    p {
	margin-bottom: 35px;
    }

    a {
	text-decoration: none;
	color: darken($temp, 35);
	font-weight: 700;

	&:hover {
	    text-decoration: underline;
	    color: darken($temp, 27);
	}
    }

    hr {
	border: 1px solid transparent;
	text-align: center;

	&::before {
	    content: '* * *'
	}
    }

}

.Warning {
    margin: 25px 0;
    background-color: green;
    color: white;
    font-size: 18px;
    padding: 10px;
}
