@import "../../shared.module.scss";

.Hero {
    height: 100vh;
    position: relative;
    margin-top: -$topBarHeight;
    background-image: url('../../assets/images/mountain.jpg');
    background-size: auto 100vh;
    background-position: 100% 100%;

    @include gt-xs {
	background-position: center;
	background-size: cover;
    }

    .CallToAction {
	padding-top: 25%;
	padding-left: 20%;
	width: 100%;

	@include gt-xs {
	    padding-top: 12%;
	    padding-bottom: 7%;
	    padding-left: 67%;
	}

	p {
	    color: $guyabano;
	    font-weight: 400;
	    margin-block-start: 0.2em;
	    margin-block-end: 0.2em;

	    @include gt-xs {
		font-size: 18px;
	    }
	}

	p:first-of-type {
	    font-size: 36px;
	    font-weight: 700;

	    @include gt-xs {
		font-size: 48px;
	    }
	}

	Button {
	    margin-top: 15px;
	}
    }

}
