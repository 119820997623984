@import "../../shared.module.scss";

.GdprBanner {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 19700101;
    box-shadow: -1px -4px 5px -6px rgba(163,161,163,1);

    .GdprBannerContainer {
	background-color: rgba(255,255,255,0.9);
	padding: 52px 20px 32px 20px;

	@include gt-xs {
	    padding: 32px 86px;
	}
    }

    .GdprBannerText {
	color: #42506e;
	font-size: 13px;
	line-height: 18px;
	margin-top: 3px;
	max-width: 970px;

	a {
	    color: $primaryColor;

	    &:hover {
		opacity: 0.8;
	    }
	}
    }

    .GdprBannerDismiss {
	position: absolute;
	right: 5px;
	top: 10px;
	background-color: transparent;
	font-size: 18px;
	color: #000;
	opacity: 0.3;
	transition: opacity 0.2s;

	&:hover {
	    background-color: transparent;
	    opacity: 0.5;
	}
    }
}
