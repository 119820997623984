@import "../../shared.module.scss";

.Mission {
    background-color: #f8f8f8;
    padding: 80px 0;

    .Icons {
	display: none;

	@include gt-xs {
	    font-size: 45px;
	    color: #385764;
	    display: inline;
	}
    }

    .Text {
	color: #385764;
	font-size: 18px;
	text-align: center;
	font-style: italic;
	line-height: 30px;
	font-weight: 300;
	letter-spacing: 1.1px;

	@include gt-xs {
	    max-width: 80%;
	    font-size: 22px;
	}
    }
}
