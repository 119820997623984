@import "../../shared.module.scss";

.Hero {
    height: 500px;
    background-image: url('../../assets/images/blog_home_hero.jpg');
    background-size: cover;
    margin-bottom: 100px;
}

.BlogContentPreview {
    height: 500px;
    background-color: #fff;
    max-width: 95%;
    margin-bottom: 50px;
    border: 1px solid #eee;

    @include gt-xs {
	height: 200px;
	width: 70%;
	max-width: 800px;
    }

    .Image {
	height: 300px;
	background-size: cover;
	position: relative;
	opacity: 1;
	background-position: center center;

	@include gt-xs {
	    height: auto;
	    background-size: cover;
	    position: relative;
	    opacity: 1;
	}

	a {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    right:0;
	    left: 0;
	}

	&:hover {
	    opacity: 0.9;
	    transition: opacity 300ms;
	}
    }

    .ContentWrapper {
	height: 100%;
	padding: 15px;
	box-sizing: border-box;

	@include gt-xs {
	    padding: 20px;
	}

	h4 {
	    margin-block-start: 0;
	    margin-block-end: 0;
	    font-size: 16px;
	    color: #385764;
	    font-weight: 700;
	    text-decoration: none;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;

	    @include gt-xs {
		font-size: 18px;
	    }

	    &:hover {
		text-decoration: underline;
		opacity: 0.8;
	    }
	}

	p {
	    font-size: 14px;
	    color: #385764;
	    line-height: calc(14px * 1.6);
	}

	p.Tag {
	    margin-block-start: 0;
	    margin-block-end: 0;
	    color: #333;
	    font-size: 12px;
	}

	span.Date {
	    color: #333;
	    font-size: 12px;
	}

	a > span.ReadMore {
	    color: #333;
	    font-size: 12px;

	    &:hover {
		text-decoration: underline;
		opacity: 0.8;
	    }
	}

	.X {
	    height: calc(100% - 40px);
	}
    }
}

.BlogHome {
    background-color: #f8f8f8;
}
