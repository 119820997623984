.StatusWrapper {

    height: 100%;

    .Status {

	padding: 100px;

	p {
	    color: red;
	    text-decoration: underline;
	}

	.Up {
	    color: green;
	}
    }


}
